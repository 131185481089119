.nav-logo {
  margin: 6px 0;
  width: 7%;
}
.nav-bar {
  background-color: transparent !important;
  box-shadow: none !important;
  position: relative !important;
}
%nav-link {
  text-decoration: none;
  margin-right: 40px;
  font-size: 15px;
  font-weight: 800;
  font-family: "notosans-regular";
  color: #000;
}
.nav-link-mobile {
  @extend %nav-link;
  width: 100%;
  font-family: "notosans-regular";
}

.nav-link-desktop {
  @extend %nav-link;
  // color: #fff;
}
.start-project {
  @extend %nav-link;
  background-color: #000;
  color: #fff;
  padding: 7px 10px 10px 10px;
  border-radius: 50px;
  margin-right: 0;
}
.start-project-mobile{
  @extend %nav-link;
  background-color: #000;
  color: #fff;
  padding:1px 10px 1px 10px;
  border-radius: 50px;
  margin-right: 0;
}
