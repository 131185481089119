.footer-container {
  background-color: #fff;
  padding: 48px 30px;
  font-family: notosans-regular;
  align-items: center;
}
.footer-container-mobile {
  @extend .footer-container;
  padding: 10px;
}
.footer-caption {
  text-align: center;
}

.footer-social-icons {
  text-align: right;
  img {
    margin-right: 10px;
    font-size: 30px;
  }
}

.footer-social-icons-mobile {
  @extend .footer-social-icons;
  text-align: center;
}

.footer-email a {
  color: #000;
  text-decoration: none;
}
