.project-text-fld {
  border: 1px solid #fff !important;
  background: #fff;
  color: #000;
  border-radius: 10px;
  input {
    padding: 10px;
    
  }
}

.pjt-question{
    margin-bottom: 20px !important;
}
.quest-list li{
    margin-bottom: 20px;
}
.pjt-options{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 10px;
}
.pjt-option-label{
    padding: 10px;
    // background-color: #56554D;
    background-color: #FFF;
    color:#000;
    margin: 10px 0;
    width: 100%;
    border-radius: 10px;
}
.pjt-radio-btn{
    display: none !important;
}
.error-message{
    color:#cc0000;
}

.submit-btn{

    background-color: #FFF !important;
    color: #000 !important;
    text-transform: none !important;
    border-radius: 20px !important;


}