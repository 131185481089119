.start-project {
  background-color: #000;
  color: #fff;
  padding: 2px 18px 5px 18px ;
  border-radius: 50px;
  margin-right: 0;
  width: max-content;
  line-height: 36px !important;
  font-weight: bold !important;
}
.timeline-heading {
  font-weight: bold !important;
  width: max-content;
  line-height: 36px !important;
}
.timeline-gif {
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.timeline-gif-right {
  @extend .timeline-gif;
  float: right;
}
.timeline-gif-left {
  @extend .timeline-gif;
  float: left;
}
.timline-detail{
  width:70%;
  text-align:justify;
}
.timeline-detail-right{
  @extend .timline-detail;
  // float:right;
}

.timeline-left-detail{
  display: flex;
  flex-direction: column;
  align-items: end;
}
.timeline-knowmore-btn{

  background-color: #000 !important;
  border-radius: 20px !important;
  text-transform: none !important;
}