.banner-see-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    color: #000;
  }
}
.see-more-btn {
  padding: 0 !important;
  text-transform: none !important;
  color: #000 !important;
}
.banner-hask-link{
  z-index: 100;
}