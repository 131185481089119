.cnt-text-fld {
//   border: 1px solid #fff !important;
  background: #fff;
  color: #000;
//   border-radius: 10px;
  margin-bottom: 20px !important;
  input {
    padding: 10px;
  }
}
.error-message {
  color: #cc0000;
  margin-top: -20px;
  margin-bottom: 15px;
}
.cnt-label {
//   display: flex;
//   align-items: center;
  margin-bottom: 20px !important;
}
.submit-btn{

    background-color: #000 !important;
    border-radius: 20px !important;
    text-transform: none !important;
}